<template>
    <TabTopBar :quizData="getquizSingleData" :active_tab="active_tab" @changeTab="update_tab" :class="loader ? 'cursor-pointer-none' : '' "/>
    <component :is="current_component" :quizData="getquizSingleData"/>
</template>
<script>
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import TabTopBar from './components/TabBar.vue'
import Questionaire from '@/components/custom-components/quiz/Questionaire.vue'
import Automation from '@/components/custom-components/quiz/Automation.vue'
import Theme from '@/components/custom-components/quiz/Theme.vue'
import Publish from '@/components/custom-components/quiz/Publish.vue'
import Entries from '@/components/custom-components/quiz/Entries.vue'
import Statistics from '@/components/custom-components/quiz/StatisticsScreen.vue'
import {default as Redirect} from '@/components/custom-components/quiz/Redirect.vue'
import JumpLogic from '@/components/custom-components/quiz/JumpLogic.vue'
import { defineComponent ,onMounted} from 'vue'
import { mapActions,mapGetters } from "vuex";
export default defineComponent({
     components:{
        TabTopBar,
        Questionaire,
        Theme,
        Redirect,
        Publish,
        Entries,
        Automation,
        Statistics,
        JumpLogic
    },
    setup() {
    onMounted(() => {
      setCurrentPageTitle("Quiz");
    });
    },
    data(){
        return{
            active_tab:1,
            current_component:"Questionaire",
            loader:false
            // getquizSingleData:{}
        }
    },
    computed:{
        ...mapGetters({
            getquizSingleData:'Quiz/getquizSingleData'
        })
    },
    methods:{
        ...mapActions({
            getSingleQuiz:"Quiz/getSingleQuiz"
        }),
        getQuizData(id){
            this.loader=true
            this.getSingleQuiz(id).then((res)=>{
                this.loader=false
                if(res.data.status==true){
                    // this.getquizSingleData=res.data.data
                }else{
                    this.$router.push({name: 'QuizIndex'})
                }
            }).catch(()=>{
                this.loader=false
                this.$router.push({name: 'QuizIndex'})
            })
        },
        update_tab(tab){
            this.active_tab=tab
            if(tab==1)this.current_component='Questionaire'
            if(tab==2)this.current_component='Theme'
            if(tab==3)this.current_component='Redirect'
            if(tab==4)this.current_component='Automation'
            if(tab==5)this.current_component='Publish'
            if(tab==6)this.current_component='Entries'
            if(tab==7)this.current_component='Statistics'
            if(tab==8   )this.current_component='JumpLogic'
            if(this.$route.query.tab && this.$route.query.tab=='entries' || this.$route.query.tab=='statistics'){
                  window.history.pushState({}, document.title, window.location.pathname);
            }   
        }
    },
    created(){
        if(this.$route.params.id){
            this.getQuizData(this.$route.params.id)
        } 
        if(this.$route.query.tab){
            if(this.$route.query.tab=='entries'){
                this.current_component='Entries'
                this.active_tab=6
            }else if(this.$route.query.tab=='statistics'){
                this.current_component='Statistics'
                this.active_tab=7
            }
        }
    }
    // setup() { 
    // },
   
})
</script>
